<template>
  <div>
    <div class="flex align-center mrt10 size16">
      <div class="mrr5 Grey_color">订单编号：</div>
      <div class="mrr5">{{ tableList.order_sn }}</div><el-button v-if="tableList.order_sn" type="text" @click="copyClick( tableList.order_sn )">复制</el-button>
    </div>

    <div class="flex align-center mrt10 size16">
      <div class="mrr5 Grey_color">支付时间：</div>
      <div class="mrr5">{{ tableList.pay_time }}</div>
    </div>

    <div class="flex align-center mrt10 size16">
      <div class="mrr5 Grey_color">
        卖家收货信息：
      </div>
      <div class="flex align-center">
        <div class="mrr5">
          {{  tableList.address }},{{  tableList.consignee }},{{  tableList.mobile }}
        </div>
        <el-button type="text" @click="copyClick( tableList.address.concat(tableList.address, tableList.mobile ))">复制</el-button>
      </div>
    </div>

    <!-- 明细 -->
    <div class="bg-white pd10">
      <div class="size18">包裹明细</div>
      <div class="flex align-center size16 mrt10">
        <div class="mrr5">物流单号：</div>
        <div class="mrr5" v-if="tableList.order_logistics">{{ tableList.order_logistics.logistics_no }}<el-button type="text" @click="goDeliver(tableList)">修改单号</el-button></div>
      </div>
      <div class="flex align-center size16 mrt10">
        <div class="mrr5">物流公司：</div>
        <div class="mrr5" v-if="tableList.order_logistics">{{  tableList.order_logistics.logistics_company }}</div>
      </div>
      <div class="flex align-center size16 mrt10">
        <div class="mrr5">发货时间：</div>
        <div class="mrr5" v-if="tableList.order_logistics">{{  tableList.order_logistics.created_at }}</div>
      </div>

      <div class="size18 mrt20 mrb20">物流动态</div>
      <el-timeline>
        <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
          {{activity.status}}
        </el-timeline-item>
      </el-timeline>
    </div>

    <el-dialog title="修改运营单号" :visible.sync="dialogVisible" width="50%" @open="getmaterialFlow()">
      <el-form ref="shipmentsForm" :model="shipmentsForm" label-width="80px">
        <el-form-item label="物流单号">
          <el-input v-model="shipmentsForm.logistics_no" style="width: 450px;"></el-input>
        </el-form-item>
        <el-form-item label="物流公司">
          <el-select v-model="shipmentsForm.logistics_type" placeholder="请选择" style="width: 450px;" filterable>
            <el-option v-for="item in logisticsList" :key="item.type" :label="item.name" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toDeliverGoods">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      shipmentsForm: {
        order_id: "",
        logistics_no: "",
        logistics_type: "",
      },
      tableList: {},
      logisticsList: [],
      activities: [],
    };
  },

  created() {
    let order_id = this.$route.query.id;
    this.getList(order_id);
  },
  mounted() {},
  computed: {},

  methods: {
    async getList(order_id) {
      const { data } = await this.$Api.orderlogisticInfo(order_id);
      console.log("获取物流", data);
      this.tableList = data;
      this.activities = data.logistics_log.list;
      console.log("this.activities", this.activities);
    },
    copyClick(context) {
      navigator.clipboard
        .writeText(context)
        .then(() => {
          this.$message.success("复制成功");
        })
        .catch((err) => {
          this.$message.error("复制失败");
        });
    },
    goDeliver(row) {
      console.log("要修改的id", row);
      this.dialogVisible = true;
      this.shipmentsForm.order_id = row.id;
      this.shipmentsForm.logistics_no = row.order_logistics.logistics_no;
      this.shipmentsForm.logistics_type = row.order_logistics.logistics_type;
    },
    async getmaterialFlow() {
      const { data } = await this.$Api.orderLogistic();
      this.logisticsList = data;
      // console.log("物流公司列表", data);
    },
    async toDeliverGoods() {
      console.log("提交123");
      const data = await this.$Api.orderSend(this.shipmentsForm);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("修改成功");
      this.getList(this.$route.query.id);
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang='scss' scoped>
</style>